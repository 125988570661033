import React, { useEffect } from 'react';
import { navigate } from '@reach/router';
import { graphql, PageProps } from "gatsby";

import Head from 'src/components/head';
import { DataProps } from 'src/lib/storyblokSourceTypes';
import { SeoStoryblok } from 'src/storyblok-component-types';

export default ({ data }: PageProps<DataProps>) => {
    const seoSlug = data.seo.nodes[0];
    const seoContent: SeoStoryblok = JSON.parse(seoSlug?.content || "");
    useEffect(() => {
        navigate('/us', { replace: true });
    });
    return <Head seo={seoContent} />;
};

export const pageQuery = graphql`
  query {
    seo:allStoryblokEntry(filter: {full_slug: {eq: "seo"}}) {
      nodes {
        full_slug
        slug
        content
      }
    }
}`;
